.NavBar {
    z-index: 999;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    background-color: rgba(0,0,0,0.1);
    position: fixed;
    display: flex;
    width: 100%;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none; 
    justify-content: space-between;
    overflow-y: hidden;
}

.MobileNavBar {
    z-index: 1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    scrollbar-width: none;
    -ms-overflow-style: none; 
}

.MobileNavBarItem {
    height: 50px;
    text-decoration: none;
    color: white; 
    font-family: 'Nevduplenysh-Regular';
    font-size: 30px;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
}

.MobileNavBarItem a {
    text-decoration: none;
    color: white; 
    min-width: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.NavBarItem a {
    text-decoration: none;
    color: white; 
    text-align: center;
    font-family: 'Nevduplenysh-Regular';
    font-size: calc(0.2vw + 20px);
}

.NavBarItem {
    height: 50px;
    text-decoration: none;
    color: white; 
    font-family: 'Nevduplenysh-Regular';
    font-size: 30px;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
}

.NavBarItem.active {
    border-bottom: 2px solid white;
}

.NavBarItem:hover {
    border-bottom: 2px solid white;
}

.Logo {
    height: 100%;
    max-height: 70px; 
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}