.Section {
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.Title {
    font-family: 'eastseadokdocyrillic';
    font-size: calc(4vw + 70px);
    font-weight: 500;
    line-height: 60%;
    text-align: center;
    width: 100%;
}

.Description_Bold {
    color: white; 
    font-size: 16px; 
    font-family: 'Open Sans';
    text-align: center; 
    line-height: 30px; 
    font-weight: 700; 
    width: 100%;
}

.Description {
    color: white; 
    font-size: 16px; 
    font-family: 'Open Sans';
    text-align: center; 
    line-height: 30px; 
    width: 100%;
}

.Padding1 {
    width: 512px;
    height: 100%;
}

.MainButton {
    max-width: calc(100% - 40px);
    border: none;
    padding: 10px 2px 2px 2px;
    margin: 0;
    font-family: 'Nevduplenysh-Regular';
    font-size: calc(0.3vw + 25px);
    cursor: pointer;
    outline: inherit;
    letter-spacing: 2px;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.40);
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.40);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.40);
    transition: all 0.2s ease-in;
    text-transform: uppercase;
}

.MainButton:hover {
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.60);
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.60);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.60);
}
  

@media (max-width: 1024px) {
    .Padding1 {
        width: 256px;
    }
}

@media (max-width: 768px) {
    .Padding1 {
        width: 0px;
    }

    .Section {
        justify-content: center;
    }
}